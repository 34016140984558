@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,700;1,400&display=swap);
* {
  margin: 0 auto;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  /*max-width: 1200px;*/
}

body {
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}

header {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
}

div.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  padding: 0 0px;
}

div.cardContainer {
  display: grid;
  width: 100%;
  grid-gap: 50px;
  grid-template-columns: repeat(3, minmax(300px, 1fr));

  margin-top: 100px;
}

ul {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

span.updateText {
  width: 100%;
  font-size: 16px;
  color: #c3c3c3;
  text-align: end;
  padding: 20px 0;
}

a {
  text-decoration: none;
  color: #000;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 20px 0;
  background-color: #fff;
}

@media only screen and (max-width: 768px) {
  div.cardContainer {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
  ul {
    grid-template-columns: repeat(2, 1fr);
  }
  li {
    padding: 10px 0;
  }
  li strong {
    margin-bottom: 10;
    font-size: 18px;
  }
  li span {
    margin-bottom: 0;
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) {
  div.cardContainer {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  ul {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  div.cardContainer {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
  }
  ul {
    grid-template-columns: repeat(4, 1fr);
  }
}

.cardInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 300px; */
  height: 100px;
  justify-content: space-around;
  border-radius: 5px;
  padding: 10px 0;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
}

.cardInfo span.number {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}

.cardInfo span {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.orange {
  background-color: orange;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

li {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 20px;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
}

li strong {
  margin-bottom: 20px;
  font-size: 20px;
}

li span {
  font-weight: normal;
  font-size: 14px;
}

div.stateInfo {
  display: flex;
  flex-direction: column;
}

div.stateInfo span {
  margin-bottom: 10px;
}

div.stateItem {
  display: flex;
  flex-direction: row;
}

div.stateItem p {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}

