li {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 20px;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
}

li strong {
  margin-bottom: 20px;
  font-size: 20px;
}

li span {
  font-weight: normal;
  font-size: 14px;
}

div.stateInfo {
  display: flex;
  flex-direction: column;
}

div.stateInfo span {
  margin-bottom: 10px;
}

div.stateItem {
  display: flex;
  flex-direction: row;
}

div.stateItem p {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.red {
  background-color: red;
}

.orange {
  background-color: orange;
}
