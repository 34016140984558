@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,700;1,400&display=swap');

* {
  margin: 0 auto;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  /*max-width: 1200px;*/
}

body {
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
  background: #f0f0f5;
  -webkit-font-smoothing: antialiased;
}
